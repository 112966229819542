import React, { useEffect, useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import DatePicker from "react-multi-date-picker";
import { Button, TextField } from "@mui/material";
import { BiUpArrowAlt, BiDownArrowAlt } from "react-icons/bi";
import { DataGrid } from "@mui/x-data-grid";
import { AddCircleOutline } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useParams } from "react-router-dom";

import {
  createCustomDate,
  formatearFechaDDMMYY,
  formatearFechaHoraYYYYMMDDHHMMSS,
  formatearHoraHHMM
} from "../utils/dates";
import { showSuccessMessage } from "../redux/actions";
import { useDispatch } from "react-redux";
import { useRequestHandler } from "../utils/useRequestHandler";
import { useTypeThenRun } from "../utils/useTypeThenRun";
import Servicios from "../services";

export const EtapasConcurso = () => {
  const { _concursoId } = useParams();
  const [fecha, setFecha] = useState(new Date());
  const [textoBusqueda, setTextoBusqueda] = useState("");
  const [textoBusquedaTmp, setTextoBusquedaTmp] = useState("");
  const [conjuntos, setConjuntos] = useState([]);
  const [conjuntosActuan, setConjuntosActuan] = useState([]);
  const [etapas, setEtapas] = useState([]);
  const [etapa, setEtapa] = useState();
  const [etapaId, setEtapaId] = useState(0);
  const dispatch = useDispatch();
  const sendRequest = useRequestHandler();
  const [obtener, setObtener] = useState(false);

  useEffect(() => {
    sendRequest(Servicios.ObtenerEtapas, [_concursoId], data => {
      setEtapas(data?.data);
    });
  }, [obtener]);

  useEffect(() => {
    if (etapaId) {
      sendRequest(Servicios.ObtenerEtapaPorId, [etapaId], data => {
        setEtapa(data?.data);
        setFecha(new Date(data?.data?.fecha && data?.data?.fecha != "0001-01-01T00:00:00" ? data?.data?.fecha : ""));
        setConjuntosActuan(
          data?.data?.conjuntos.map(x => {
            return { ...x, fechaHora: formatearHoraHHMM(x.fechaHora) };
          })
        );
      });
    }
  }, [etapaId]);

  const columnsConjuntos = [
    { field: "nombre", headerName: "Nombre", flex: 99 },

    {
      field: "actionAdd",
      headerName: "",
      sortable: false,
      align: "right",
      flex: 1,
      renderCell: params => {
        const onClick = e => {
          setConjuntos(
            conjuntos?.map((x, index) => {
              if (x.id == params?.row?.id) {
                setConjuntosActuan([...conjuntosActuan, x]);
                return { ...x, agregado: true };
              } else {
                return x;
              }
            })
          );
          setTextoBusqueda("");
        };

        return <AddCircleOutline className="action-icon" onClick={onClick} />;
      }
    }
  ];

  const columnsConjuntosActuan = [
    {
      field: "fecha",
      headerName: "Hora",
      flex: 20,
      renderCell: params => {
        return (
          <TextField
            size="small"
            value={params.row.fechaHora}
            onChange={e => {
              let input = e.target.value;
              input = input.replace(/[^0-9:]/g, "");
              if (input.length > 2 && input.indexOf(":") === -1)
                input = input.slice(0, 2) + ":" + input.slice(2);

              let indice = conjuntosActuan.indexOf(params.row);
              let arr = [...conjuntosActuan];
              arr[indice].fechaHora = input;
              setConjuntosActuan(arr);
            }}
          />
        );
      }
    },
    { field: "nombre", headerName: "Nombre", flex: 99 },
    {
      field: "actionSubir",
      headerName: "",
      sortable: false,
      align: "right",
      flex: 1,
      renderCell: params => {
        const onClick = e => {
          let indice = conjuntosActuan.indexOf(params.row);
          let arr = [...conjuntosActuan];
          arr.splice(indice, 1);
          arr.splice(indice - 1 >= 0 ? indice - 1 : 0, 0, params.row);
          setConjuntosActuan(arr);
        };

        return (
          <BiUpArrowAlt size={30} className="action-icon" onClick={onClick} />
        );
      }
    },
    {
      field: "actionBajar",
      headerName: "",
      sortable: false,
      align: "right",
      flex: 1,
      renderCell: params => {
        const onClick = e => {
          let indice = conjuntosActuan.indexOf(params.row);
          let arr = [...conjuntosActuan];
          arr.splice(indice, 1);
          arr.splice(
            indice + 1 < arr.length ? indice + 1 : arr.length,
            0,
            params.row
          );
          setConjuntosActuan(arr);
        };

        return (
          <BiDownArrowAlt size={30} className="action-icon" onClick={onClick} />
        );
      }
    },
    {
      field: "actionDelete",
      headerName: "",
      sortable: false,
      align: "right",
      flex: 1,
      renderCell: params => {
        const onClick = e => {
          setConjuntosActuan(
            conjuntosActuan.filter(x => x.id != params?.row?.id)
          );
        };

        return <DeleteIcon className="action-icon" onClick={onClick} />;
      }
    }
  ];

  useTypeThenRun(textoBusquedaTmp, () => setTextoBusqueda(textoBusquedaTmp));

  useEffect(() => {
    sendRequest(
      Servicios.ConjuntosConcursan,
      [textoBusqueda, _concursoId],
      data => {
        setConjuntos(data?.data);
      }
    );
  }, [textoBusqueda, conjuntosActuan.length]);

  const handleGuardar = () => {
    sendRequest(
      Servicios.CargarEtapaConcurso,
      [
        {
          id: etapaId,
          fecha: formatearFechaHoraYYYYMMDDHHMMSS(fecha),
          conjuntos: conjuntosActuan.map(x => {
            return {
              conjuntoId: x.id,
              fechaHora:
                x.fechaHora?.length == 5
                  ? formatearFechaHoraYYYYMMDDHHMMSS(
                    createCustomDate(new Date(fecha), x.fechaHora)
                  )
                  : formatearFechaHoraYYYYMMDDHHMMSS(x.fechaHora)
            };
          })
        }
      ],
      () => {
        dispatch(showSuccessMessage("Etapa cargada correctamente"));
        setObtener(!obtener);
      }
    );
  };

  return (
    <>
      <h3>Etapas del concurso</h3>
      <div
        className="d-flex row my-3 justify-content-between "
        style={{ maxHeight: 400, overflow: "auto" }}
      >
        <DataGrid
          onRowClick={e => {
            setEtapaId(e.row.id);
          }}
          rows={etapas}
          columns={[
            {
              field: "fecha",
              headerName: "Fecha",
              renderCell: params => {
                return (
                  <>
                    {params.row.fecha
                      ? formatearFechaDDMMYY(params.row.fecha)
                      : ""}
                  </>
                );
              }
            },
            { field: "nombre", headerName: "Etapa", flex: 30 }
          ]}
          pagination={false}
          checkboxSelection={false}
        />
      </div>
      {/* <div className="d-flex row my-3 justify-content-between">
        <div className="col text-center">
          <Select
            style={{ width: 300 }}
            size="small"
            placeholder="Etapa"
            value={etapaId}
            onChange={e => setEtapaId(e.target.value)}
          >
            <MenuItem key={0} value={0}>
              {"Seleccionar etapa..."}
            </MenuItem>
            {etapas?.map(c => (
              <MenuItem key={c.id} value={c.id}>
                {c.nombre}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div> */}
      <hr></hr>
      {etapaId != 0 && (
        <>
          <div className="d-flex row">
            <div
              id="datepicker-EtapasConcurso"
              className="col datepicker text-right"
            ></div>
          </div>
          <div className="d-flex row">
            <div className="col-3 m-2">
              <DatePicker
                className="w-100"
                format="DD/MM/YYYY"
                value={fecha}
                onChange={e => {
                  setFecha(e.toDate());
                }}
              />
              <TextField
                className="w-100 my-1"
                size="small"
                label="Buscar conjunto"
                value={textoBusquedaTmp}
                onChange={e => setTextoBusquedaTmp(e.target.value)}
              />

              <DataGrid
                style={{ maxHeight: 400, overflow: "auto" }}
                pagination={false}
                disableColumnFilter
                disableColumnMenu
                hideFooterPagination
                rows={conjuntos}
                columns={columnsConjuntos}
                checkboxSelection={false}
                className="no-header"
              />
            </div>
            <div className="col-8 my-3">
              <DataGrid
                style={{ maxHeight: 400, overflow: "auto" }}
                pagination={false}
                disableColumnFilter
                disableColumnMenu
                disableDensitySelector
                hideFooterPagination
                rows={conjuntosActuan}
                columns={columnsConjuntosActuan}
                checkboxSelection={false}
              />
            </div>
          </div>
          <hr></hr>
          <div className="d-flex row justify-content-center w-auto">
            <div className="col text-right">
              <Button
                variant={"outlined"}
                onClick={() => {
                  window.history.go(-1);
                }}
              >
                Cancelar
              </Button>
            </div>
            <div className="col text-right">
              <Button variant={"contained"} onClick={handleGuardar}>
                Guardar
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default withAuthenticationRequired(EtapasConcurso, {
  onRedirecting: () => <Loading />
});
