import React, { useEffect } from "react";
import LandingWebsite from "./LandingWebsite";
export const AppRedirect = () => {
  useEffect(() => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (
      /iPad|iPhone|iPod/.test(userAgent) &&
      !window.MSStream &&
      process.env.REACT_APP_APP_STORE_URL
    ) {
      window.location.replace(process.env.REACT_APP_APP_STORE_URL);
    } else if (process.env.REACT_APP_GOOGLE_PLAY_URL) {
      window.location.replace(process.env.REACT_APP_GOOGLE_PLAY_URL);
    } else {
      window.location.replace("https://carnavalapp.uy");
    }
  }, []);

  return <>{<LandingWebsite></LandingWebsite>}</>;
};
export default AppRedirect;
